import {RAAS_PARTNERS_CUSTOM_WIDTH} from '@renofi/utils/src/const';
import {
  constants,
  getRaasPartnerName,
  useRaasSourceLender,
  useUTMTags,
  useSrLO,
} from '@renofi/utils';
import {RAAS_PARTNER_LOGOS, COTTAGE_LOGO} from '@renofi/modules';
import {useContractorBySlug} from '@renofi/api';

import {getIsGCPartnerSource} from '../../util';

import {Logo} from './styled';

const PartnerLogo = () => {
  const srLO = useSrLO();
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const isCottageLead = utmTags.source === 'cottage';
  const raasPartner = useRaasSourceLender(utmTags);
  const {contractor} = useContractorBySlug({
    skip: raasPartner || !getIsGCPartnerSource(utmTags),
    variables: {urlSlug: utmTags.source},
  });

  const src = raasPartner
    ? RAAS_PARTNER_LOGOS[raasPartner]
    : isCottageLead
      ? COTTAGE_LOGO
      : contractor?.logoUrl;

  if (srLO || !src) {
    return null;
  }

  const alt = raasPartner
    ? getRaasPartnerName(raasPartner)
    : isCottageLead
      ? 'Cottage'
      : contractor?.businessName;
  return (
    <Logo
      isRaas={raasPartner}
      width={RAAS_PARTNERS_CUSTOM_WIDTH[raasPartner]}
      data-testid="partner-logo"
      src={src}
      alt={alt}
    />
  );
};

export default PartnerLogo;
