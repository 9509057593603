import styled from '@emotion/styled/macro';
import {Image, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

const HEADER_HEIGHT = 70;

export const Logo = styled(Image)(
  mediaQuery({
    height: 'auto',
    maxHeight: HEADER_HEIGHT,
    marginRight: [16, 20],
  }),
  ({isRaas, width}) => ({
    ...(isRaas
      ? {
          width: width ? width : 115,
        }
      : {maxWidth: 115}),
  }),
);

export const Container = styled(Flex)(
  mediaQuery({
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ['0 16px', '0 32px'],
    background: 'white',
    height: HEADER_HEIGHT,
  }),
);

export const LogoContainer = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    width: 'auto',
    justifyContent: 'space-between',
  }),
);
