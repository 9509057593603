import React, {useEffect, useState} from 'react';

import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {RateInput} from '@renofi/components';

import {Heading, Narrow, Buttons} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

const MortgageRate = ({updateScenario}) => {
  const next = useNext();
  const {firstMortgageRate} = useReactiveVar(leadVar);
  const [formData, setFormData] = useState(firstMortgageRate);
  const displayValue = !formData ? '' : String(formData * 100);

  useEffect(() => sendEvent('POS/First-Mortgage-Rate-Page'), []);

  function onSubmit() {
    const rate = Number(formData) / 100;
    setLead({firstMortgageRate: rate});
    updateScenario({firstMortgageRate: rate});

    sendEvent('POS/First-Mortgage-Rate-Entered', {firstMortgageRate: rate});
    next();
  }

  return (
    <>
      <Narrow maxWidth={640}>
        <Heading>
          Enter your <strong>existing mortgage rate</strong> (best guess is ok)
        </Heading>
      </Narrow>

      <RateInput
        autofocus
        value={displayValue}
        onComplete={(value) => setFormData(value)}
      />

      <Buttons
        disableNext={!formData}
        onNext={onSubmit}
        value={firstMortgageRate}
      />
    </>
  );
};

export default MortgageRate;
