import React from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {ScheduleCall} from '@renofi/modules';

import {leadVar} from '../../../api/cache';

const ScheduleModal = ({
  visible,
  onSuccess,
  title,
  router,
  subtitle,
  onClose,
  editEnabled = true,
}) => {
  const lead = useReactiveVar(leadVar);

  function onCloseWithEvent() {
    sendEvent('Dashboard/Close-Calendar-Click');
    onClose && onClose();
  }

  if (!visible) return null;

  return (
    <ScheduleCall
      visible={visible}
      title={title}
      subtitle={subtitle}
      editEnabled={editEnabled}
      lead={lead}
      router={router}
      onClose={onCloseWithEvent}
      onSuccess={onSuccess}
      analyticsPrefix="POS"
    />
  );
};

ScheduleModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  callType: PropTypes.string,
  editEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  calendarId: PropTypes.string,
};

export default ScheduleModal;
