import React, {useState, useRef, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Text, Box} from 'rebass';
import {useLocation} from 'react-router-dom';

import {
  FooterLinks,
  Toggle,
  Copyright,
  FooterLegalMessage,
  FooterLink,
} from '@renofi/components';
import {scrollToBottom, useScreenSize} from '@renofi/utils';

import {Small} from '../../components';

import {ReactComponent as TreesIcon} from './img/background.svg';
import {ReactComponent as TickIcon} from './img/tick.svg';
import {FooterLegalWrapper, IconWrapper, UpDownButton} from './styled';

const FooterLegal = ({onExpand, onCollapse, children}) => {
  const [isExpand, setIsExpand] = useState(true);
  const [autoCollapsed, setAutoCollapsed] = useState(false);
  const {isMobile, isDesktop} = useScreenSize();
  const containerRef = useRef(null);
  const height = containerRef?.current?.getBoundingClientRect().height;
  const location = useLocation();

  useEffect(() => {
    onExpand(height);
  }, [height]);

  useEffect(() => {
    if (location.pathname !== '/loan-type' && !autoCollapsed && isMobile) {
      setIsExpand(false);
      setAutoCollapsed(true);
      setTimeout(() => onClick());
    }
  }, [location]);

  function onClick() {
    if (!isExpand) {
      onExpand(height);
      scrollToBottom('root');
      setIsExpand(!isExpand);
    } else {
      setTimeout(() => onCollapse(), 100);
      setIsExpand(!isExpand);
    }
  }

  return (
    <FooterLegalWrapper
      height={height}
      isExpand={isDesktop || isExpand}
      ref={containerRef}>
      {children}
      <IconWrapper>
        <TreesIcon />
      </IconWrapper>

      <UpDownButton isExpand={isDesktop || isExpand} onClick={onClick}>
        <TickIcon />
      </UpDownButton>

      <Small css={{userSelect: 'none'}}>
        If at any point we refer to <strong>applicants</strong> we mean the
        applicants applying with a lender. You may choose to apply or not with
        any lender, although only certain lenders provide RenoFi type loans.
        RenoFi is not a lender and does not make credit decisions.{' '}
      </Small>

      <Toggle show={isDesktop}>
        <FooterLinks center />
      </Toggle>

      <Toggle show={isMobile}>
        <Text fontWeight="strong">
          <Small mt={30}>
            <strong>
              <Copyright />
            </strong>

            <Box mt={10}>
              <FooterLink target="_blank" href="https://www.renofi.com/notices">
                Important notices and info - NMLS # 1802847
              </FooterLink>

              <FooterLegalMessage center />
            </Box>
          </Small>
        </Text>
      </Toggle>
    </FooterLegalWrapper>
  );
};

FooterLegal.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export default FooterLegal;
