import {Box, Flex} from 'rebass';

import {useSrLO} from '@renofi/utils';

import {AdvisorText} from './styled';

const DEFAULT_ADVISOR = {
  firstName: 'Kylie',
  avatar:
    'https://ucarecdn.com/28849732-005b-438a-a942-789ac0b30689/-/preview/260x260/-/quality/smart_retina/-/format/auto/',
};

const AdvisorWelcome = ({pg = [30, 50], ...props}) => {
  const srLO = useSrLO();
  const advisor = DEFAULT_ADVISOR;

  if (srLO) return null;

  return (
    <Box pb={pg} {...props} data-testid="assistantWidget">
      <Flex justifyContent="center">
        <Flex>
          <img
            width="64"
            height="64"
            style={{width: 64, height: 64, marginTop: 4, borderRadius: 100}}
            src={advisor.avatar}
          />
          <AdvisorText>
            <strong>Hi, I’m {advisor.firstName}</strong> 👋
            <br />
            Your RenoFi Advisor
          </AdvisorText>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AdvisorWelcome;
