import {useState} from 'react';

import {Flex} from 'rebass';

import {constants, useSrLO} from '@renofi/utils';

import {setModal} from '../../api/cache';

import {
  AdvisorBox,
  AdvisorText,
  AdvisorButton,
  ExpandedImage,
  CollapsedImage,
} from './styled';
import arrowBtn from './img/arrowBtn.svg';

const AdvisorWidget = () => {
  const srLO = useSrLO();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClick = () => {
    setIsExpanded((prevIsVisible) => !prevIsVisible);
  };

  if (!srLO) {
    return null;
  }

  return (
    <>
      <AdvisorBox isExpanded={isExpanded} data-testid="advisorWidget">
        <Flex justifyContent="center">
          <img
            width="64"
            height="64"
            style={{width: 64, height: 64, marginTop: 4, borderRadius: 100}}
            src={srLO.avatar}
          />
          <AdvisorText>
            <strong>Need help?</strong>
            <div>
              <AdvisorButton
                onClick={() => setModal(constants.MODAL_TYPES.SCHEDULE)}>
                Schedule a call
              </AdvisorButton>{' '}
              📞 with {srLO.firstName}.
            </div>
          </AdvisorText>
        </Flex>
      </AdvisorBox>
      {isExpanded ? (
        <ExpandedImage onClick={handleClick} src={arrowBtn} />
      ) : (
        <CollapsedImage onClick={handleClick} src={srLO.avatar} />
      )}
    </>
  );
};

export default AdvisorWidget;
