import React from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';
import {constants, useSrLO} from '@renofi/utils';

import {ScheduleModal, LeadDetailsModal} from '../Modals';
import {useModals} from '../../hooks';

const ScheduleModals = ({lead}) => {
  const {setModal, modal, prevModal} = useModals();
  const srLO = useSrLO();

  const validateDetails = (leadDetails) => {
    return Boolean(
      leadDetails.email &&
        leadDetails.firstName &&
        leadDetails.lastName &&
        leadDetails.phone,
    );
  };
  const isAllDetails = validateDetails(lead);

  return (
    <>
      <ScheduleModal
        visible={isAllDetails && modal === constants.MODAL_TYPES.SCHEDULE}
        title={'Great! When should your loan officer call you?'}
        subtitle={'Please pick a time from the calendar below.'}
        onSuccess={() => {
          sendEvent('POS/SRLO-Schedule-Success');
        }}
        router="sr-loan-officers"
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      <LeadDetailsModal
        visible={
          (!isAllDetails && [constants.MODAL_TYPES.SCHEDULE].includes(modal)) ||
          modal === constants.MODAL_TYPES.LEAD_DETAILS
        }
        lead={lead}
        title={
          'Schedule a 15 minute call with ' +
          (srLO ? srLO.firstName : 'a RenoFi Advisor')
        }
        cta="Choose from the available times on the next screen."
        onSubmit={async () => {
          setModal(
            modal === constants.MODAL_TYPES.LEAD_DETAILS ? prevModal : modal,
          );
        }}
        onCancel={() =>
          setModal(isAllDetails ? prevModal : constants.MODAL_TYPES.NONE)
        }
        onClose={() =>
          setModal(isAllDetails ? prevModal : constants.MODAL_TYPES.NONE)
        }
        onBrowserAction={() => setModal(constants.MODAL_TYPES.NONE)}
      />
    </>
  );
};

ScheduleModals.propTypes = {
  lead: PropTypes.object,
};

export default ScheduleModals;
