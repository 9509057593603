import styled from '@emotion/styled/macro';

import check from './check.svg';

export const StyledList = styled('ul')({
  listStyleImage: `url('${check}')`,
  paddingLeft: '20px',
  marginBlockStart: 0,
});

export const ListItem = styled('li')({
  lineHeight: '25px',
  textAlign: 'left',
  padding: '5px 0',
});
