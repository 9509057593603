import React, {useEffect, useRef, useState} from 'react';

import {every, isNil} from 'lodash';
import {useReactiveVar} from '@apollo/client';
import {Box} from 'rebass';

import {Label, TextField, AddressField, Toggle} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';
import {useHomeValuationEstimate, useUpdateScenarioPersonal} from '@renofi/api';
import {formatAddress} from '@renofi/utils/src/format';
import {
  hasNumbers,
  isAddressValid,
  replaceWithUSPSAbbreviations,
  useRenovationUtils,
} from '@renofi/utils';
import {AddressInputs} from '@renofi/modules';
import {isAuthenticated} from '@renofi/utils/src/lead';

import {leadVar, resetAllAddressFields, setLead} from '../api/cache';
import {useNext} from '../hooks';
import {
  Heading,
  Buttons,
  Narrow,
  GoogleReviewSmall,
  IconsSocialProofWrapper,
  Funding,
  FeatureReview,
  FundingSmall,
  SubHeading,
} from '../components';
import {getNextPathForState} from '../Zip/utils';

function Address() {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {city, state, county, streetAddress, streetAddressShort, zipCode, id} =
    lead;
  const location = {
    city,
    county,
    state,
    streetAddress,
    streetAddressShort,
    zipCode,
  };
  const locationRef = useRef(location);
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({id});
  const {isFinalizedPurchase} = useRenovationUtils(lead);
  const queryHomeValue = useHomeValuationEstimate();
  const [errors, setErrors] = useState({});
  const [isManual, setIsManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(city || county || zipCode || state);
  const isFormValid = every(errors, isNil) && isAddressValid(location);
  const showMissingNumberWarning =
    isFormValid && !hasNumbers(location.streetAddress);
  locationRef.current = location;

  useEffect(() => sendEvent('POS/Address-Page'), []);

  useEffect(() => {
    return () => {
      if (!isAddressValid(locationRef.current)) {
        resetAllAddressFields();
      }
    };
  }, []);

  function onAddressSelect(location) {
    setShowAll(true);
    setLead(location);
  }

  function onChange(key, value, err) {
    const newLocation = {
      ...location,
      ...(key === 'zipCode' ? {zipCode: value} : {}),
      [key]: value,
    };
    setLead({
      ...newLocation,
      location: newLocation, // also set address under location key to support submitScenario mutation
    });
    setErrors({...errors, [key]: err});
  }

  function onStreetChange(value, err) {
    const newLocation = {
      ...location,
      streetAddress: value,
      streetAddressShort: replaceWithUSPSAbbreviations(value),
    };
    setLead({
      ...newLocation,
      location: newLocation, // also set address under location key to support submitScenario mutation
    });
    setErrors({...errors, streetAddress: err});
  }

  async function submitHomeValueEstimation() {
    setLoading(true);
    const address = formatAddress(location, {short: true});

    try {
      const rsp = await queryHomeValue({address});
      const {valueDollars} = rsp?.data?.homeValuationEstimate || {};
      if (valueDollars) {
        sendEvent('POS/Estimation-Success', {plunkValue: valueDollars});
      }
      setLead({estimatedHomeValue: valueDollars || null});
    } catch (e) {
      sendEvent('POS/Estimation-Failed');
    }

    onNext();
  }

  function onNext() {
    if (isAuthenticated(id)) {
      updateScenarioPersonal({variables: {id, zipCode, location}});
    }

    sendEvent('POS/Address-Entered', location);
    setLoading(false);
    const path = isFinalizedPurchase
      ? '/purchase-price'
      : '/home-value-estimation';
    next(getNextPathForState(state, path));
  }

  function onNoneFoundClick() {
    setShowAll(true);
    setIsManual(true);
  }

  return (
    <>
      <Heading>The property address is</Heading>
      <SubHeading small>
        If available, we’ll automatically show you the value of the property on
        the next screen.
      </SubHeading>

      <Narrow maxWidth={500} css={{minHeight: 400}}>
        <Toggle show={!isManual}>
          <AddressField
            xLarge
            isManual={isManual}
            value={streetAddress}
            onClickManual={onNoneFoundClick}
            onSelect={onAddressSelect}
            onChange={(value, err) => onStreetChange(value, err)}
          />
        </Toggle>

        <Toggle show={isManual}>
          <Box>
            <Label small htmlFor="city">
              Street address
            </Label>
            <TextField
              css={{boxShadow: '0px 0px 7px 0px #00A0FF1A'}}
              xLarge
              value={streetAddress}
              onChange={(value, err) => onStreetChange(value, err)}
              id="streetAddress"
              name="streetAddress"
              type="text"
              required
            />
          </Box>
        </Toggle>

        <Toggle show={showAll}>
          <AddressInputs onChange={onChange} {...location} />
        </Toggle>

        <Narrow maxWidth={showMissingNumberWarning ? '100%' : 352}>
          <Buttons
            loading={loading}
            fullWidth
            onNext={submitHomeValueEstimation}
            disableNext={!isFormValid || loading}
            nextButtonProps={
              showMissingNumberWarning
                ? {css: {width: 330, paddingTop: '8px !important'}}
                : {}
            }
            customContinueText={
              showMissingNumberWarning ? (
                <>
                  Continue
                  <Box as="small" css={{display: 'block'}}>
                    without house number
                  </Box>
                </>
              ) : null
            }
          />
        </Narrow>
      </Narrow>
      <IconsSocialProofWrapper>
        <Funding />
        <FeatureReview />
        <FundingSmall />
        <GoogleReviewSmall
          reviewerName="brandon fleischman"
          review="We just closed on our RenoFi Loan from a local credit union and our renovation is starting soon..."
        />
      </IconsSocialProofWrapper>
    </>
  );
}

export default Address;
