import React from 'react';

import PropTypes from 'prop-types';

import {StyledList, ListItem} from './styled';

const List = ({values}) => {
  return (
    <StyledList>
      {values.map((value, i) => (
        <ListItem key={i}>{value}</ListItem>
      ))}
    </StyledList>
  );
};

List.propTypes = {
  values: PropTypes.array,
};

export default List;
