import {useRef} from 'react';

import {useReactiveVar} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {omitBy, isEqual, isEmpty} from 'lodash';

import {
  useSubmitScenario,
  useUpdateScenario,
  useRenovationScenario,
} from '@renofi/api';
import {completedHelocMock} from '@renofi/api/src/queries/renovationScenario.mock';
import {getItem} from '@renofi/utils';

import {leadVar} from './cache';

import {onScenarioUpdate} from './';

export default function useApi({mock} = {}) {
  const lead = useReactiveVar(leadVar);
  const deferredLead = useRef(lead);
  const params = useParams();
  const isMock = mock || params?.id === 'mock';
  const cookieId = getItem('renofi_id');
  const {id: paramId} = useParams();

  const id = lead?.id || paramId || cookieId;
  const {submitScenario} = useSubmitScenario({
    onCompleted: onScenarioUpdate,
  });
  const {updateScenario} = useUpdateScenario();
  const queryScenario = useRenovationScenario({id});

  return {
    async updateScenario(scenario) {
      const scenarioId = scenario?.id || id;
      if (!scenarioId) return;

      // Execute mutation only if actual changes
      const scenarioDiffs = omitBy(scenario, (value, key) =>
        isEqual(value, deferredLead.current[key]),
      );
      if (isEmpty(scenarioDiffs)) return;

      const {data} = await updateScenario({
        variables: {...scenarioDiffs, id: scenarioId},
      });
      const scenarioUpdates = data?.updateScenario;
      onScenarioUpdate(scenarioUpdates);
      deferredLead.current = lead;
      return scenarioUpdates;
    },
    async createScenario(scenario) {
      const {data} = await submitScenario({variables: scenario});
      deferredLead.current = lead;
      return data?.submitScenario;
    },
    async queryScenario(id, {updateCache = true} = {}) {
      const urlParams = new URLSearchParams(window.location.search);
      const completedParam = urlParams.get('completed');
      const {data = {}} = id ? await queryScenario({id}) : {};
      const scenario = isMock ? completedHelocMock.data.result : data?.result;

      if (!scenario) {
        return {};
      }

      if (updateCache) {
        onScenarioUpdate(scenario);
      }

      deferredLead.current = lead;

      return {
        ...scenario,
        lead: {
          ...scenario.lead,
          ...(completedParam === 'null' ? {prequalCompletedAt: null} : {}),
        },
      };
    },
  };
}
