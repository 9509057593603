import {useState, useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {Toggle} from '@renofi/components';
import {useScreenSize, useUTMTags} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

import {getIsSRLoSource} from '../util';
import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading, List} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as RenovationIcon} from './img/renovation.svg';
import {ReactComponent as SoldIcon} from './img/sold.svg';

const VALUES_RENOVATE = [
  'Renovate my current home',
  "Renovate a home I'm buying",
  'Build a new home',
  'Build an ADU',
];

const VALUES_BUY = ['Buy a new home', 'Buy land'];

const options = [
  {
    title: <List values={VALUES_RENOVATE} />,
    value: 'renovate_current_home',
    icon: RenovationIcon,
  },
  {
    title: <List values={VALUES_BUY} />,
    value: 'buy_current_home',
    icon: SoldIcon,
  },
];

function LoanTypeInitial() {
  const {isMobile} = useScreenSize();
  const {utmTags} = useUTMTags(UTM_COOKIE_KEYS.slo);
  const [showButtons, setShowButtons] = useState(null);
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const path = '/loan-type';

  useEffect(
    () => setShowButtons(!isNil(lead.loanTypeInitial)),
    [lead.loanTypeInitial],
  );

  useEffect(() => {
    sendEvent('POS/Loan-Type-Initial-Page');
  }, []);

  useEffect(() => {
    if (utmTags === '' || !getIsSRLoSource(utmTags)) {
      next(path);
    }
  }, [utmTags]);

  async function onSubmit(loanTypeInitial) {
    setLead({loanTypeInitial});

    sendEvent('POS/Loan-Type-Initial-Entered', {loanTypeInitial});

    if (loanTypeInitial === 'buy_current_home') {
      window.location.assign(
        'https://renofi.my1003app.com/' + utmTags.source + '/inquiry',
      );
    } else {
      next(path);
    }
  }

  return (
    <>
      <Heading>I’m looking for the perfect loan to</Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={lead.loanTypeInitial}
        verticalItem={true}
        css={{height: '100%', paddingBottom: [null, 4]}}
      />
      <Toggle show={isMobile || showButtons}>
        <Buttons
          disableBack
          hideBack
          disableNext={isNil(lead.loanTypeInitial)}
          onNext={() => onSubmit(lead.loanTypeInitial)}
          value={lead.loanTypeInitial}
        />
      </Toggle>
    </>
  );
}

export default LoanTypeInitial;
