import {useEffect, useState} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {Toggle} from '@renofi/components';
import {constants, useScreenSize} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading, AdvisorWelcome} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as RenovationIcon} from './img/renovation.svg';
import {ReactComponent as NewHomeIcon} from './img/buying.svg';
import {ReactComponent as ADUIcon} from './img/adu.svg';
import {ReactComponent as BuildHome} from './img/build-home.svg';

const options = [
  {
    title: 'Renovating my current home',
    value: 'renovate_current_home',
    icon: RenovationIcon,
  },
  {
    title: "Renovating a home I'm buying",
    value: 'renovate_home_being_purchased',
    icon: NewHomeIcon,
  },
  {
    title: 'Accessory Dwelling Unit (ADU)',
    value: 'renovate_or_build_adu',
    icon: ADUIcon,
  },
  {
    title: 'Building a home from the ground up',
    value: 'build_a_home_from_ground_up',
    icon: BuildHome,
  },
];

function LoanType({updateScenario}) {
  const {isMobile} = useScreenSize();
  const next = useNext();
  const [showButtons, setShowButtons] = useState(null);
  const lead = useReactiveVar(leadVar);

  useEffect(() => {
    sendEvent('POS/Loan-Type-Page');
  }, []);

  useEffect(() => setShowButtons(!isNil(lead.loanSubject)), [lead.loanSubject]);

  async function onSubmit(loanSubject) {
    const isPurchase = loanSubject === constants.LOAN_SUBJECT.HOME_PURCHASED;
    const isBuild = loanSubject === constants.LOAN_SUBJECT.HOME_BUILD;
    const isAdu = loanSubject === constants.LOAN_SUBJECT.ADU;

    setLead({loanSubject});

    updateScenario({loanSubject});

    sendEvent('POS/Loan-Type-Entered', {loanSubject});

    let path = '/loan-amount';
    if (isPurchase) path = '/purchase-stage';
    if (isBuild) path = '/land';
    if (isAdu) path = '/adu';
    next(path);
  }

  return (
    <>
      <AdvisorWelcome />
      <Heading>My project is</Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={lead.loanSubject}
        css={{height: '100%', paddingBottom: [null, 4]}}
      />
      <Toggle show={isMobile || showButtons}>
        <Buttons
          disableBack={!lead.loanTypeInitial}
          hideBack={!lead.loanTypeInitial}
          disableNext={isNil(lead.loanSubject)}
          onNext={() => onSubmit(lead.loanSubject)}
          value={lead.loanSubject}
        />
      </Toggle>
    </>
  );
}

export default LoanType;
