import {noop} from 'lodash';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';
import {LeadDetailsModal} from '@renofi/modules';
import {savePersonalLeadData} from '@renofi/utils/src/lead';

import {setLead} from '../../api/cache';

const LeadDetails = ({
  visible,
  lead,
  title,
  subtitle = '',
  designLead,
  cta,
  onSubmit = noop,
  onCancel = noop,
  onClose = noop,
}) => {
  const handleClose = () => {
    sendEvent('POS/Personal-Details-Modal-Closed');
    onClose();
  };

  const handleSubmit = async (leadDetails) => {
    savePersonalLeadData(leadDetails, lead?.id);

    setTimeout(() => {
      setLead(leadDetails);

      sendEvent('POS/Personal-Details-Changed', {
        changedDetails: Object.keys(leadDetails).filter(
          (k) => lead[k] !== leadDetails[k],
        ),
      });
      onSubmit(leadDetails);
    }, 1000);
  };

  return (
    <LeadDetailsModal
      lead={lead}
      visible={visible}
      title={title}
      subtitle={subtitle}
      designLead={designLead}
      cta={cta}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onCancel={onCancel}
    />
  );
};

LeadDetails.propTypes = {
  lead: PropTypes.object,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default LeadDetails;
