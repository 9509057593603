import React, {useEffect, useState} from 'react';

import {Box} from 'rebass';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {ThemeProvider} from '@emotion/react';
import {some} from 'lodash';
import {v4 as uuid} from 'uuid';
import ScheduleModals from 'components/Schedule/ScheduleModals';

import {
  useFlagsStatus,
  useUnleashClient,
  useUnleashContext,
} from '@renofi/utils/src/feature-flags';
import {breakpoint} from '@renofi/utils/src/mediaQuery';
import {Analytics, Auth, CookieConsentModal, Toggle} from '@renofi/components';
import {
  useScrollToTopBetweenRoutes,
  useUTMTags,
  useUpdateUnleashContext,
  isGeneralContractorLead,
  isPartnerSiteLead,
  constants,
  useCookies,
  getDomain,
  getQueryParamPreFills,
  useFeatureFlagEvents,
  logger,
  deleteCookie,
} from '@renofi/utils';
import {initGooglePlaces} from '@renofi/utils/src/places';
import {
  DASHBOARD_REDIRECT_READY_KEY,
  isAuthenticated,
} from '@renofi/utils/src/lead';

import {initLogAnalytics} from '../analytics';
import {usePosition} from '../hooks';
import {ModalsProvider} from '../components/Modals';
import {Header, PageLoader} from '../components';
import {leadVar, setLead, loanProductsVar} from '../api/cache';
import Footer from '../Footer';
import LoanOptionsExperimental from '../LoanOptions';
import Schedule from '../Schedule';
import Docs from '../Docs';
import {useFetchLead} from '../LoanOptions/hooks';
import PointTurndown from '../Partners/Point';
import VontiveTurndown from '../Partners/Vontive';
import SplashTurndown from '../Partners/Splash';
import SoFiTurndown from '../Partners/SoFi';

import AnimatedRouter from './AnimatedRouter';
import {Container, Wrapper} from './styled';

const theme = {
  breakpoints: [`${breakpoint}px`],
};
const POS_STEP = 'posStep';

function App() {
  const lead = useReactiveVar(leadVar);
  const {flagsReady} = useFlagsStatus();
  const loanProducts = useReactiveVar(loanProductsVar);
  const location = useLocation();
  const position = usePosition();
  const [extraPadding, setExtraPadding] = useState(false);
  const {persistUTMTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {updateCookie} = useCookies(POS_STEP, null);

  const {cookie: id} = useCookies('renofi_id');
  const {fetchLead, fetchStatus, setFetchStatus} = useFetchLead();
  const path = window.location.pathname;
  const client = useUnleashClient();
  const loading = !flagsReady || fetchStatus === 'loading';

  useScrollToTopBetweenRoutes();

  useFeatureFlagEvents(client, 'POS');

  const updateContext = useUnleashContext();

  useUpdateUnleashContext(updateContext, lead);

  useEffect(() => {
    if (!isAuthenticated(id || lead.id)) {
      setLead({experimentalId: uuid()});
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated(id || lead.id)) {
      deleteCookie(DASHBOARD_REDIRECT_READY_KEY);
    }
  }, [id, lead.id, isAuthenticated(id)]);

  useEffect(() => {
    if (loading) return;
    document.body.classList.add('loaded');
  }, [loading]);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) setTimeout(() => initGooglePlaces(apiKey), 5000);
  }, []);

  useEffect(() => {
    (async () => {
      if (!path?.includes('loan-options') && !path?.includes('redirecting')) {
        await fetchLead(id);
      } else {
        setFetchStatus('skip');
      }
    })();
  }, [id]);

  useEffect(() => {
    const domain = getDomain();
    if (domain) {
      updateCookie({value: path, numberOfDays: 7, domain: `.${domain}`});
    }
  }, [path]);

  useEffect(() => {
    const queryParamPreFills = getQueryParamPreFills();

    if (some(queryParamPreFills, (val) => Boolean(val))) {
      setLead(queryParamPreFills);
    }

    persistUTMTags((utmTags) =>
      isGeneralContractorLead(utmTags) || isPartnerSiteLead(utmTags) ? 1 : 365,
    );
  }, []);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      logger.log(lead);
    }
  }, [location]);

  const showFooterBackground =
    !location.pathname.includes('/hei/point') &&
    !location.pathname.includes('/investment/vontive');

  return (
    <Analytics lead={lead} loanProducts={loanProducts}>
      <ThemeProvider theme={theme}>
        <ModalsProvider>
          <Box width="100%" height="100%">
            <Toggle show={loading}>
              <PageLoader />
            </Toggle>

            <Toggle show={!loading}>
              <Container
                pb={[extraPadding, 'inherit']}
                bgXPos={position * -350}>
                <Box
                  flex={1}
                  height="100%"
                  display={['block', 'block']}
                  flexDirection="column">
                  <Header />

                  <Switch>
                    <Route exact path="/auth">
                      <Auth failedPath="/loan-type" />
                    </Route>
                    <Route path="/hei/point/:id">
                      <PointTurndown />
                    </Route>
                    <Route path="/investment/vontive/:id">
                      <VontiveTurndown />
                    </Route>
                    <Route path="/personal/splash/:id">
                      <SplashTurndown />
                    </Route>
                    <Route path="/personal/sofi/:id">
                      <SoFiTurndown />
                    </Route>
                    <Route path="/loan-options/:id">
                      <Wrapper width={1140}>
                        <LoanOptionsExperimental />
                      </Wrapper>
                    </Route>
                    <Route path="/docs">
                      <Docs />
                    </Route>
                    <Route path="/schedule">
                      <Schedule />
                    </Route>

                    <AnimatedRouter />
                  </Switch>
                </Box>
                <Footer
                  showBackground={showFooterBackground}
                  onExpandCollapse={setExtraPadding}
                />
              </Container>
            </Toggle>
          </Box>
          <ScheduleModals lead={lead} />
        </ModalsProvider>
        <CookieConsentModal
          initLogAnalytics={initLogAnalytics}
          isLead={Boolean(lead?.id)}
        />
      </ThemeProvider>
    </Analytics>
  );
}

export default App;
