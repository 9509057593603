import styled from '@emotion/styled/macro';
import {Box, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const AdvisorBox = styled(Box)(
  mediaQuery({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: -155,
    right: ['unset', 20],
    left: [20, 'unset'],
    padding: 20,
    height: 80,
    textAlign: 'left',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 70px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '8px',
    transition: 'clip-path 0.1s ease-in-out, opacity 0.1s ease-in-out',
  }),
  ({isExpanded}) => ({
    ...(isExpanded
      ? {
          opacity: 1,
          clipPath: 'inset(0 0 0 0)',
        }
      : {
          opacity: 0,
          clipPath: 'inset(100% 0 0 0)',
        }),
  }),
);

export const AdvisorText = styled(Text)(
  mediaQuery({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 10,
  }),
);

export const AdvisorButton = styled('span')(
  mediaQuery({
    display: 'inline',
    cursor: 'pointer',
    textDecoration: 'underline',
  }),
);

export const ExpandedImage = styled('img')(
  mediaQuery({
    position: 'absolute',
    top: -70,
    right: ['unset', 20],
    left: [20, 'unset'],
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
);

export const CollapsedImage = styled('img')(
  mediaQuery({
    position: 'absolute',
    top: -70,
    right: ['unset', 20],
    left: [20, 'unset'],
    width: 48,
    height: 48,
    borderRadius: 100,
    cursor: 'pointer',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
);
