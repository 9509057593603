import {useEffect, useRef} from 'react';

import {Box} from 'rebass';

import {chilipiper} from '@renofi/analytics';

import {ReactComponent as CloseIcon} from '../img/close-icon.svg';
import {CPPopup, PaddedWrapper} from '../styled';

export const POPUP_TYPES = {
  SCHEDULED_CALL: 'ScheduledCall',
};

const PopupTitles = ({title, subtitle}) => (
  <>
    {title && (
      <span className="chilipiper-title">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="subtitle">{subtitle}</span>
          </>
        )}
      </span>
    )}
  </>
);

const ScheduleCall = ({
  lead,
  title = 'No problem, when would be a better time to have a quick call?',
  subtitle = 'Continue On! Rates and terms are not locked and could change!',
  onClose,
  router,
  analyticsPrefix,
}) => {
  const effectExecuted = useRef(false);
  useEffect(() => {
    if (effectExecuted.current) return;
    effectExecuted.current = true;

    chilipiper.initSchedule({
      lead,
      domElement: `#schedule-call`,
      router: router,
      analyticsPrefix,
      onClose: () => {
        document.dispatchEvent(new CustomEvent('CP-Calendar-Close'));
      },
      onSuccess: ({slot}) => {
        document.dispatchEvent(
          new CustomEvent('CP-Calendar-Success', {detail: slot}),
        );
      },
    });
  }, []);

  return (
    <CPPopup>
      <div id="cp-container">
        <PaddedWrapper>
          <Box
            data-testid="close-button"
            css={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
            <CloseIcon onClick={onClose} />
          </Box>

          <PopupTitles title={title} subtitle={subtitle} />
          <Box id="schedule-call"></Box>
        </PaddedWrapper>
      </div>
    </CPPopup>
  );
};

export default ScheduleCall;
