import React from 'react';

import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloClient, ApolloProvider, from, HttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';

import initPolyfills from '@renofi/utils/src/polyfills';
import {FlagProvider} from '@renofi/utils/src/feature-flags';
import {
  retryLinkConfig,
  fetchWithRateLimitHandler,
  dataDogLink,
} from '@renofi/api';
import {logGraphQLError, badQueryErrorLink} from '@renofi/utils';

import App from './App';
import {initAnalytics} from './analytics';
import {cache} from './api/cache';
import './index.css';
import '@renofi/analytics/src/chilipiper.css';

initPolyfills();
initAnalytics();

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});
const retryLink = new RetryLink(retryLinkConfig);
const authLink = setContext((_, {headers}) => {
  return {headers};
});
// Log any GraphQL errors or network error that occurred
const errorLink = onError(logGraphQLError);

const client = new ApolloClient({
  cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([
    errorLink,
    badQueryErrorLink,
    dataDogLink,
    authLink,
    retryLink,
    httpLink,
  ]),
});

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: `pos-app-${process.env.REACT_APP_ENV}`,
  environment: process.env.REACT_APP_ENV,
};

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <FlagProvider config={unleashConfig}>
        <Router>
          <App />
        </Router>
      </FlagProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
